/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useCallback } from "react";
import Globe from "react-globe.gl";
import { useQuery } from "react-query";
import { getTransactions } from "../data/getTransactions";
import countries from "../data/json/countries.json";
import { Color, MeshPhongMaterial } from "three";
import { getWorldData } from "../data/getWorldData";
import { Arc } from "../types/arc";
import { Ring } from "../types/ring";
import { Label } from "../types/label";
import currency from "../data/json/curreny.json";
import * as c from "../constants";
import { WorldDataFeature } from "../types/worldData";
import { SlideOutSettings } from "./settings";
import usePageRefresh from "../hooks/usePageRefresh";

export const WorldGlobe = () => {
  const [transactionCount, setTransactionCount] = useState<number>();
  const [arcsData, setArcsData] = useState<Arc[]>([]);
  const [ringsData, setRingsData] = useState<Ring[]>([]);
  const [labelData, setLabelData] = useState<Label[]>([]);
  const [getGlobeType, setGlobeType] = useState<string>("1");
  const [jsonHelper, setJsonHelper] = useState<number>(0);
  const globeEl = useRef<any>();
  const time = useRef<number>();
  const { data, refetch } = useQuery("transactions", getTransactions, {});
  const worldData = useQuery("worlddata", getWorldData);
  usePageRefresh();

  useEffect(() => {
    const globe = globeEl.current as any;
    if (globe) {
      setTimeout(() => {
        const directionalLight = globe
          .scene()
          .children.find((obj3d: any) => obj3d.type === "DirectionalLight");
        if (directionalLight) {
          directionalLight.intensity = 0.5;
        }
      });
      globe.pointOfView(c.MAP_CENTER);
      globe.controls().autoRotate = true;
      globe.controls().autoRotateSpeed = c.GLOBE_ANIMATION_SPEED;
      setRingsData([]);
      setArcsData([]);
      setLabelData([]);
    }
  }, [getGlobeType]);
  useEffect(() => {
    let interval: NodeJS.Timer;
    let countt = 0;

    if (data) {
      try {
        time.current = 1;
        const max = data.transactions[0].secondsOffsetFromQueryTime || 0;
        let interval = setInterval(function () {
          if (time.current! <= max) {
            try {
              const t = data.transactions.filter(
                (tr) => tr.secondsOffsetFromQueryTime === time.current
              );
              if (t && t.length > 0) {
                t.forEach((k) => {
                  countt = countt + 1;

                  const toISO = k.toCountryISO as keyof typeof countries;
                  const fromISO = k.fromCountryISO as keyof typeof countries;
                  const start = countries[fromISO];
                  const end = countries[toISO];
                  if (start && end) {
                    emitArc(
                      {
                        startLat: start.latitude,
                        startLng: start.longitude,
                        endLat: end.latitude,
                        endLng: end.longitude,
                        type: k.distributorCategory
                          .toLowerCase()
                          .replace(" ", ""),
                      },
                      {
                        amount: k.sendAmount,
                        currency: k.sendCurrencyISO,
                        from: k.fromCountryISO,
                        to: k.toCountryISO,
                        lat: end.latitude,
                        lng: end.longitude,
                        type: k.distributorCategory,
                        productType: k.productType,
                      }
                    );
                  } else {
                    console.log("missing country", fromISO, toISO);
                  }
                });
              }
              time.current = time.current! + 1;
              setTransactionCount(
                data.totalSinceMidnight[0].totalSinceMidnight + countt
              );
            } catch (error) {
              console.log("data error", error, data);
            }
          } else {
            clearInterval(interval);
            refetch();
            setJsonHelper(jsonHelper + 1); // force refresh for json file
          }
        }, 1000);
      } catch (error) {
        console.log("transaction error", error);
      }
    } else {
      console.log("no data");
    }
    return () => {
      clearInterval(interval);
    };
  }, [data, jsonHelper]);

  const emitArc = (arc: Arc, label: Label) => {
    setArcsData((curArcsData) => [...curArcsData, arc]);
    setTimeout(
      () => setArcsData((curArcsData) => curArcsData.filter((d) => d !== arc)),
      c.ARC_TIME
    );
    // add and remove target rings
    setTimeout(() => {
      const targetRing = { lat: arc.endLat, lng: arc.endLng };
      setRingsData((curRingsData) => [...curRingsData, targetRing]);
      setLabelData((curLblData) => [...curLblData, label]);

      setTimeout(() => {
        setRingsData((curRingsData) =>
          curRingsData.filter((r) => r !== targetRing)
        );
        setLabelData((curLblData) => curLblData.filter((r) => r !== label));
      }, c.FLIGHT_TIME * c.ARC_REL_LEN);
    }, c.FLIGHT_TIME);
  };

  const handlePoligonColor = useCallback((k: any) => {
    const data = k as WorldDataFeature;
    return data.properties.colour;
  }, []);

  const getColour = (key: string, data: boolean = false) => {
    console.log(key)
    console.log(key === "checkout- western union")
    switch (key) {
      case "partners":
        return data ? "#004a59" : "#abe9f5";
      case "consumer":
        return data ? "#800055" : "#f6a6db";
      case "p2p":
        return data ? "#ffaa00" : "#fae3b6";
      case "digicel":
        return data ? "#599eff" : "#e0e9f8";
      case "checkout- western union":
        return data ? "rgb(0,0,0)" : "rgb(0,0,0)";
    }
    return "#004a59";
  };
  const handleHtmlElement = (d: object) => {
    const data = d as Label;
    const fromISO = data.from as keyof typeof countries;
    const toISO = data.to as keyof typeof countries;
    const cur = data.currency as keyof typeof currency;
    const from = countries[fromISO];
    const to = countries[toISO];
    const el = document.createElement("div");
    const cursym = currency[cur] ? currency[cur].symbol : cur;
    const isDomestic = from.country === to.country;

    const isVoucher = data.productType === "Voucher";

    if (isVoucher) {
      console.log(data)
    }

    if (isVoucher) {
      el.innerHTML = `<div class='top'>${data.type} |  <span class='cur'>${cursym}${data.amount}</span></div>
                    <hr/>
                    <div class='bottom'><span class='country'>${from.country}</span> <span></span> <span class='country'>Voucher</span></div>`;
    }
    else if (isDomestic) {
      el.innerHTML = `<div class='top'>${data.type} |  <span class='cur'>${cursym}${data.amount}</span></div>
                    <hr/>
                    <div class='bottom'><span class='country'>${from.country}</span> <span></span> <span class='country'>domestic</span></div>`;
    } else {

      el.innerHTML = `<div class='top'>${data.type} |  <span class='cur'>${cursym}${data.amount}</span></div>
                    <hr/>
                    <div class='bottom'><span class='country'>${from.country}</span> <span>to</span> <span class='country'>${to.country}</span></div>`;
    }

    el.className = data.type.toLowerCase().replace(" ", "").replace(/\s/g, '');
    console.log(data.type.toLowerCase().replace(/\s/g, ''), "xxxxxxxxxx")
    return el;
  };

  const handlePolygonsData = useCallback<any>(worldData.data?.features, [
    worldData.data,
  ]);

  const handleArcColour = useCallback((k) => {
    const l = [getColour(k.type, true), getColour(k.type)];
    return l;
  }, []);

  const handleRingColour = useCallback((k) => {
    return `#73f87e`;
  }, []);

  const getGlobeImage = () => {
    switch (getGlobeType) {
      case "0":
        return "./img/earth-blue-marble.jpg";
      case "1":
        return "./img/earth_8k.jpg";
      default:
        break;
    }
  };

  const getTransactionsPerMinute = () => {
    if (!transactionCount) return "";
    const n = new Date();
    return (transactionCount / n.getHours() / 60).toFixed(2);
  }

  const urlParams = new URLSearchParams(window.location.search);
  const numbers = urlParams.get('hn');

  return (
    <>
      <img src="./img/logo.png" alt="Logo" className="logo" />
      {numbers !== "true" &&
        <>
          <div className="transactionCount">
            <span id="transactionCountTotal">{(data?.totalSinceMidnight[0].totalSinceMidnight)?.toLocaleString()}</span>
            <span>Transactions today</span>
            <span>{`per minute ${getTransactionsPerMinute()}`}</span>
          </div>
        </>}

      <SlideOutSettings onSelectGlobeType={(type) => {
        setGlobeType(type);
      }} />
      <Globe

        globeMaterial={
          getGlobeType === "2"
            ? new MeshPhongMaterial({
              color: "#18104a",
              transparent: true,
              opacity: 0.7,
              specular: new Color("#666"),
              bumpScale: 5,
            })
            : undefined
        }
        showAtmosphere
        globeImageUrl={getGlobeImage()}
        bumpImageUrl={getGlobeType === "0" ? "./img/clouds.png" : null}
        backgroundImageUrl="./img/night-sky.png"
        showGraticules={getGlobeType === "2"}
        hexPolygonsData={getGlobeType === "2" ? handlePolygonsData : null}
        hexPolygonResolution={3}
        hexPolygonMargin={0.2}
        hexPolygonColor={handlePoligonColor}
        htmlElementsData={labelData}
        htmlElement={handleHtmlElement}
        htmlTransitionDuration={0}
        ref={useCallback<any>(globeEl, [])}
        arcsData={arcsData}
        arcColor={handleArcColour}
        arcDashLength={c.ARC_REL_LEN}
        arcDashGap={2}
        arcDashInitialGap={1}
        arcDashAnimateTime={c.FLIGHT_TIME}
        arcsTransitionDuration={0}
        ringsData={ringsData}
        arcStroke={1.3}
        ringColor={handleRingColour}
        ringMaxRadius={c.RINGS_RADIUS_SIZE}
        ringPropagationSpeed={c.RING_PROPAGATION_SPEED}
        ringRepeatPeriod={(c.FLIGHT_TIME * c.ARC_REL_LEN) / c.NUM_RINGS}
      />
    </>
  );
};
