import { useState, useEffect } from 'react';

const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute (60,000 milliseconds)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return currentTime;
};

const ClockComponent = () => {
  const currentTime = useCurrentTime();

  return (
    <div className='clock'>
      {`${currentTime.toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })} ${currentTime.getHours() < 12 ? "AM" : "PM"}`}
    </div>
  );
};

export default ClockComponent;
