
import { ChangeEvent, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import PulseDot from 'react-pulse-dot'
import 'react-pulse-dot/dist/index.css'
import ClockComponent from "./clock";


interface P {
    onSelectGlobeType: (type: string) => void;
}

export const SlideOutSettings = (p: P) => {
    const { onSelectGlobeType } = p;

    const [open, setOpen] = useState(false);
    const [getGlobeType, setGlobeType] = useState<string>("1");

    const handleMapChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setGlobeType(event.target.value);
        onSelectGlobeType(event.target.value);
    };

    return (
        <>
            {!open &&
                <>
                    <div style={{ opacity: open ? ".3" : "1" }} onClick={() => {
                        setOpen(!open);
                    }} className='live-indicator'><PulseDot style={{ fontSize: '40px' }} color="#FF0000" /><p>Live</p>
                    </div>
                    <ClockComponent />
                </>
            }
            <SlidingPane
                width="300px"
                className="slide-panel"
                overlayClassName="slide-panel"
                isOpen={open}
                title="Settings"
                onRequestClose={() => {
                    setOpen(false);
                }}
            >
                <h3 className="map-type-label">Map type</h3>
                <select onChange={handleMapChange}>
                    <option value={0} selected={getGlobeType === "0"} >Earth Map V1</option>
                    <option value={1} selected={getGlobeType === "1"}>Earth Map 8K</option>
                    <option value={2} selected={getGlobeType === "2"}>Earth Polygon map</option>
                </select>
            </SlidingPane>
        </>

    )
}